import React from 'react'

const LayoutRightImageContent = ({data}) => {

  function preventImageDrag(event) {
    event.preventDefault();
  }
let grabIf
if(data.catNameEng!==undefined)
{
  if(data.catNameEng.toLowerCase().replace(/\s/g, '')==='grabbars' || data.catNameEng.toLowerCase().replace(/\s/g, '')==='grabbar' )
  {
    grabIf='grabBar'
  }
  else{
    grabIf=data.headerIndex
  }
}
else{
  grabIf=data.headerIndex
}

  return (
    data && data.rightImageUrl ? (
      <div className={`product index${grabIf}`}>
        <img src={window.config.REACT_APP_BACKEND_URL + data.rightImageUrl}
          alt=''
          onDragStart={preventImageDrag}
          crossOrigin="Anonymous"
        />
      </div>
    ) : null
  )
}
export default LayoutRightImageContent