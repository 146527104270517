import React,{useState } from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { Trans, useTranslation } from "react-i18next";
import ModalCros from '../../images/crox.png'
import {useForm} from 'react-hook-form';
import { getSummaryPageData, getVariantAllData } from '../designbathshower/designBathShowerSlice';
import { getBookNowDesignBathStatusPop,getBookNowDesignBathStatusPopClose,getDesignId,scheduleDesignBookNow } from '../designmobilelayout/mobileMenuSlice';
import { Link,useLocation, useParams } from 'react-router-dom';
import i18next from 'i18next';
import { getCampaignData } from '../campaignanalytics/CampaignAnalytics';
import { GoogleDataLayer, getTagManagerEvents } from '../layout/GoogleDataLayer';
import TextInputField from '../formfields/TextInputField';
import PhoneInputField, { ContryCodePhoneValues } from '../formfields/PhoneInputField';
import ZipCodeInputField from '../formfields/ZipCodeInputField';
import EmailInputField from '../formfields/EmailInputField';
import Checkbox from '../formfields/Checkbox';

const DesignBathShowerBookNowPop = () => {
const dispatch           =useDispatch();
const book_status        =useSelector(getBookNowDesignBathStatusPop);

const allData                   = useSelector(getVariantAllData);
const summaryDataPage           = useSelector(getSummaryPageData)

let allDataJson = { 
    'allData':allData,
}

const designId = useSelector(getDesignId);
const params = useParams();
let catid = params.id 
const isMobile = window.innerWidth < 768;
const location = useLocation(); 
let pathName;
if (location.pathname.includes('bathroom')) {
    pathName = 'design-your-own-bathroom'
} else {
    pathName = 'design-your-own-shower';
}

// Set default selected country code based on language
const [countryCode, setCountryCode] = useState(i18next.language === 'fr' ? 'CA' : 'US');

const { t } = useTranslation();

const { register, reset, handleSubmit, formState } = useForm();
const { errors } = formState;

const onSubmit = async(data, e) => {
    dispatch(getBookNowDesignBathStatusPopClose());
    GoogleDataLayer({
        event: getTagManagerEvents.book,
        summaryPrintData: summaryDataPage
    });
    // Save contact information and design and create new lead in Lead Manager
    dispatch(scheduleDesignBookNow({
        'firstName': data.firstName.trim(),
        'lastName': data.lastName.trim(),
        'email': data.email,
        'phone': ContryCodePhoneValues[countryCode] + data.phoneNumber.replace(/[()\s-]/g, ''),
        'zipCode': data.zipCode.toUpperCase(),
        'locale': i18next.language,
        'allData': allDataJson,
        'designId': designId,
        'platform': isMobile ? 'mobile' : 'web',
        'catid': catid,
        'pathName': pathName,
        'campaignInformations': getCampaignData(location)
    }));
    reset();
};

let termsAndConditionsUrl;
switch (i18next.language) {    
    case 'es':
        termsAndConditionsUrl = 'https://www.bathfitter.com/us-es/privacy-policy-es/';
        break;

    case 'fr':
        termsAndConditionsUrl = 'https://www.bainmagique.com/qc-fr/politique-de-confidentialite/';
        break;

    default:
        termsAndConditionsUrl = 'https://www.bathfitter.com/us-en/privacy-policy/';
        break;
}

  return (
(book_status===true)?(<div className="modal fade in book-consultation" id="exampleModal3"  role="dialog" data-backdrop="static" data-keyboard="false" style={{"display":"block"}}>
           
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    <span className="close" data-dismiss="modal" onClick={ () =>  dispatch(getBookNowDesignBathStatusPopClose())}><img src={ModalCros} alt=""/></span>
                </h5>
            </div>
            <div className="modal-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="fullwidthsmidd">
                        <TextInputField name="firstName"
                            placeholder={t("label.firstName")}
                            register={register}
                            requiredErrorMessage={t("error.firstNameRequired")}
                            errors={errors}
                        />
                        <TextInputField name="lastName"
                            placeholder={t("label.lastName")}
                            register={register}
                            requiredErrorMessage={t("error.lastNameRequired")}
                            errors={errors}
                        />
                        <PhoneInputField name="phoneNumber"
                            placeholder={t('label.phoneNumber')}
                            register={register}
                            required={t("error.phoneNumberRequired")}
                            patternErrorMessage={t("error.phoneNumberFormat")}
                            errors={errors}
                            countryCode={countryCode}
                            setCountryCode={setCountryCode}
                        />
                        <ZipCodeInputField name="zipCode"
                            placeholder={t("label.zipCode")}
                            register={register}
                            requiredErrorMessage={t("error.zipCodeRequired")}
                            patternErrorMessage={t("error.zipCodeFormat")}
                            errors={errors}
                        />
                        <EmailInputField name="email"
                            customClass={"col-sm-12 fullform"}
                            placeholder={t("label.email")}
                            register={register}
                            requiredErrorMessage={t("error.emailRequired")}
                            patternErrorMessage={t("error.emailFormat")}
                            errors={errors}
                        />
                        <div className="col-sm-12 fullform">
                            <button type="submit" className="btn upload">{t("button.bookNow")}</button>
                        </div>
                        <Checkbox name="termsAndConditions"
                            customClass="custom-checkbox center"
                            label={
                                <Trans t={t} i18nKey="label.termsAndConditions">
                                    I agree to the <Link to={termsAndConditionsUrl} target='_blank'>terms and conditions</Link>
                                </Trans>
                            }
                            register={register}
                            requiredErrorMessage={t("error.termsAndConditionsRequired")}
                            errors={errors}
                        />
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>):''
  )
}

export default DesignBathShowerBookNowPop